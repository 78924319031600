import React from 'react'
import { useParams } from 'react-router-dom'

import Navi from "./Navi"
import MoodSorter from './MoodSorter';

import DroppDown from "./DroppDown";

export default function Posts(props) {

    const [sortBy, setSortBy] = React.useState("createdAt");

    const { mood } = useParams()

    React.useEffect(() => {
    }, [mood]);

    const sorthandler = (e) => {
        // console.log(e);
        setSortBy(e);
    }

    return (
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <Navi mood={mood} />
                </div>
            </div>
            <div className='row mt-0 mb-4 justify-content-center'>
                <div className='col-md-8'>
                    <DroppDown sorthandler={sorthandler} sortBy={sortBy} />
                </div>
            </div>
            {/* <div className='row justify content center'>
                <DisplayAd />
            </div> */}
            <MoodSorter mood={mood} {...props} sortBy={sortBy} />
        </div>
    );

    function DisplayAd() {
        if (window.innerWidth < 768) {
            // mobile
            return (
            <>
                <div id="container-93df366d871bc864eb1c114fed4ad5aa"></div>
            </>
            )
        } else {
            // desktop 
            return (
                <>
                    <div id="container-b139cf16752a70ac77cc5dcb0d26fd3c"></div>
                </>
            )

        }
    }
}
